import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'

import { NgModule } from '@angular/core'

@NgModule({
  imports: [MatIconModule],
  providers: [MatIconRegistry]
})
export class IconModule {
  private pathToIconsFolder = 'assets/icons'

  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry
  ) {
    matIconRegistry
      .addSvgIcon('copy', this.markAsSafe(`${this.pathToIconsFolder}/copy.svg`))
      .addSvgIcon('info', this.markAsSafe(`${this.pathToIconsFolder}/info.svg`))
      .addSvgIcon('success', this.markAsSafe(`${this.pathToIconsFolder}/success.svg`))
      .addSvgIcon('column', this.markAsSafe(`${this.pathToIconsFolder}/column.svg`))
      .addSvgIcon('zoom_in', this.markAsSafe(`${this.pathToIconsFolder}/zoom-in.svg`))
      .addSvgIcon('zoom_out', this.markAsSafe(`${this.pathToIconsFolder}/zoom-out.svg`))
      .addSvgIcon('refresh', this.markAsSafe(`${this.pathToIconsFolder}/vector-active.svg`))
      .addSvgIcon('filter', this.markAsSafe(`${this.pathToIconsFolder}/filter.svg`))
      .addSvgIcon('double-arrow-left', this.markAsSafe(`${this.pathToIconsFolder}/double-arrow-left.svg`))
      .addSvgIcon('double-arrow-right', this.markAsSafe(`${this.pathToIconsFolder}/double-arrow-right.svg`))
      .addSvgIcon('fido2_builtin', this.markAsSafe(`${this.pathToIconsFolder}/fido2_builtin.svg`))

      /* Status */
      .addSvgIcon('status_new', this.markAsSafe(`${this.pathToIconsFolder}/statuses/status_new.svg`))
      .addSvgIcon('status_inProgress', this.markAsSafe(`${this.pathToIconsFolder}/statuses/status_in_progress.svg`))
      .addSvgIcon('status_finished', this.markAsSafe(`${this.pathToIconsFolder}/statuses/status_finished.svg`))
      .addSvgIcon('status_locked', this.markAsSafe(`${this.pathToIconsFolder}/statuses/status_locked.svg`))
      .addSvgIcon('status_exported', this.markAsSafe(`${this.pathToIconsFolder}/statuses/status_exported.svg`))
      .addSvgIcon('status_submitted', this.markAsSafe(`${this.pathToIconsFolder}/statuses/status_submitted.svg`))
      .addSvgIcon('status_archived', this.markAsSafe(`${this.pathToIconsFolder}/statuses/status_archive.svg`))

      /* Alerts */
      .addSvgIcon('alert_flag', this.markAsSafe(`${this.pathToIconsFolder}/alerts/alert_flag.svg`))
      .addSvgIcon('alert_two', this.markAsSafe(`${this.pathToIconsFolder}/alerts/alert_two.svg`))
      .addSvgIcon('alert_syringe', this.markAsSafe(`${this.pathToIconsFolder}/alerts/alert_syringe.svg`))
      .addSvgIcon('pcv_syringe', this.markAsSafe(`${this.pathToIconsFolder}/screenings/syringe.svg`))
      .addSvgIcon('af_heart', this.markAsSafe(`${this.pathToIconsFolder}/screenings/heart.svg`))

      /* Logos */
      .addSvgIcon('microsoft', this.markAsSafe(`${this.pathToIconsFolder}/logos/microsoft.svg`))
      .addSvgIcon('google', this.markAsSafe(`${this.pathToIconsFolder}/logos/google.svg`))
      .addSvgIcon(
        'microsoft_authenticator',
        this.markAsSafe(`${this.pathToIconsFolder}/logos/microsoft_authenticator.svg`)
      )
      .addSvgIcon('google_authenticator', this.markAsSafe(`${this.pathToIconsFolder}/logos/google_authenticator.svg`))
      .addSvgIcon('lastpass', this.markAsSafe(`${this.pathToIconsFolder}/logos/lastpass.svg`))
  }

  private markAsSafe(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url)
  }
}
