var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { NotificationMixin } from "../shared/mixins/notification.mixin";
import toastStyles from "./toast.styles";
import { classMap } from "lit/directives/class-map.js";
import timesIcon from "../shared/icons/times.icon";
import { ref } from "lit/directives/ref.js";
/**
 * A toast provides brief feedback about an operation through a message that appears temporarily.
 * @fires {Event} dismiss - Fired when the toast is dismissed and its animation is complete. Use this event to remove the toast from the DOM.
 * @slot - The content of the toast. Don't add interactive elements like buttons or links to the slot content.
 */
let Toast = class Toast extends NotificationMixin(LitElement) {
  constructor() {
    super(...arguments);
    /** The duration in milliseconds after which the toast will be dismissed. */
    this.autoDismiss = 10000;
  }
  updated(_changedProperties) {
    if (_changedProperties.has("autoDismiss")) {
      clearTimeout(this.autoDismissTimeout);
      this.autoDismissTimeout = setTimeout(() => this.dismiss(), this.autoDismiss);
    }
  }
  render() {
    const classes = classMap({
      "bi-toast": true,
      "bi-toast--dismissed": this.dismissed
    });
    return html`<div ${ref(this.notificationRef)} class=${classes}>
      <div class="bi-toast-content">
        <slot></slot>
      </div>
      <bi-button
        @click=${this.dismiss}
        variant="plain"
        class="bi-toast-close"
        size="s"
      >
        ${timesIcon("bi-toast-close-icon")}
      </bi-button>
    </div>`;
  }
  /** Dismisses the toast. Returns a promise that resolves when the animation is complete. */
  async dismiss() {
    clearTimeout(this.autoDismissTimeout);
    await super.dismiss();
  }
};
Toast.styles = toastStyles;
__decorate([property({
  reflect: true
})], Toast.prototype, "variant", void 0);
__decorate([property({
  type: Number,
  attribute: "auto-dismiss"
})], Toast.prototype, "autoDismiss", void 0);
Toast = __decorate([customElement("bi-toast")], Toast);
export { Toast };
