<app-base-dialog [displayTitle]="'about' | translate">
  <div class="dialog-container">
    <div class="info-block">
      <span class="info-line">
        <b>{{ "product_name" | translate }}:</b>
        <p>Bingli Dynamic Anamnesis</p>
      </span>

      <span class="info-line">
        <b>{{ "version_number" | translate }}:</b>
        <p>{{ version$ | async }}</p>
      </span>
    </div>

    <div class="info-block">
      <span class="img-line">
        <img
          ngSrc="assets/images/manufacture_date.png"
          height="42px"
          width="50px"
          alt="{{ 'manufacture_date' | translate }}"
        />
        <p>2020-12</p>
      </span>
    </div>

    <div class="info-block">
      <span class="img-line">
        <img
          ngSrc="assets/images/manufacturer.png"
          height="42px"
          width="50px"
          alt="{{ 'manufacturer' | translate }}"
        />
        <div>
          <p>Bingli NV</p>
          <p>Lange Gasthuisstraat 29</p>
          <p>2000 Antwerpen, Belgium</p>
        </div>
      </span>
    </div>

    <div class="info-block">
      <span class="img-line">
         <img
           height="42"
           ngSrc="assets/images/iso.png"
           alt="ISO" width="82" />
      </span>
    </div>
  </div>
</app-base-dialog>
