import { Routes } from '@angular/router';
import {
  actionsResolver,
  practitionerSettingsResolver,
  userGroupsResolver,
  userResolver,
  usersResolver,
} from '../../shared/resolvers/tenant-admin.resolver';
import { supportedLanguagesResolver } from '../../shared/resolvers/translation.resolver';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'users',
  },
  {
    path: 'users',
    resolve: {
      users: usersResolver,
    },
    runGuardsAndResolvers: 'always',
    loadComponent: () =>
      import('./admin-users/admin-users.component').then(
        (m) => m.AdminUsersComponent
      ),
  },
  {
    path: 'users/new',
    resolve: {
      userGroups: userGroupsResolver,
      supportedLanguages: supportedLanguagesResolver,
      actions: actionsResolver,
    },
    loadComponent: () =>
      import('./admin-users-form/admin-users-form.component').then(
        (m) => m.AdminUsersFormComponent
      ),
  },
  {
    path: 'users/:id',
    resolve: {
      user: userResolver,
      userGroups: userGroupsResolver,
      supportedLanguages: supportedLanguagesResolver,
      actions: actionsResolver,
      practitionerSettings: practitionerSettingsResolver,
    },
    loadComponent: () =>
      import('./admin-users-form/admin-users-form.component').then(
        (m) => m.AdminUsersFormComponent
      ),
  },
  {
    path: 'analytics',
    loadComponent: () =>
      import('./admin-analytics/admin-analytics.component').then(
        (m) => m.AdminAnalyticsComponent
      ),
  },
];
