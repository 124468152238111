import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { localStorageLanguageKey } from '../data/constants';

export const HttpLoaderFactory = (httpClient: HttpClient) => {
  return new TranslationLoader(httpClient);
};
class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    lang = this.filterNl(lang);
    localStorage.setItem(localStorageLanguageKey, lang);
    return this.http
      .get<any>(
        `${environment.translationApiUrl}/tenant-api/translation-file?type=generic_label&language=${lang}`,
      )
      .pipe(map((x) => x.data));
  }

  filterNl(lang: string): string {
    return lang === 'nl' ? 'nl-be' : lang;
  }
}
