import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, map, of, take } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { noDataPlaceholder } from '../../../data/constants';

interface EngineInfo {
  release_date: string;
  status: string;
  version: string;
}

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutDialogComponent implements OnInit {
  version$: Observable<string> = of(noDataPlaceholder);

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
    this.version$ = this.httpClient.get<EngineInfo>(environment.engineURL).pipe(
      take(1),
      map((response) => response.version),
    );
  }
}
