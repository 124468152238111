import { html } from "lit";
export default (className => html`<svg
  class=${className}
  viewBox="0 0 512 512"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
>
  <path
    d="m496.8 87.4c20-20 20-52.5 0-72.4-20-20-52.5-20-72.4 0l-168.4 168.6-168.6-168.4c-20-20-52.5-20-72.4 0s-20 52.5 0 72.4l168.6 168.4-168.4 168.6c-20 20-20 52.5 0 72.4 20 20 52.5 20 72.4 0l168.4-168.6 168.6 168.4c20 20 52.5 20 72.4 0 20-20 20-52.5 0-72.4l-168.6-168.4z"
  />
</svg>`);
