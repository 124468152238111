import { css } from "lit";
export default css`
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes enter {
    0% {
      transform: translateY(50%);
      opacity: 0;
    }
  }

  @keyframes exit {
    100% {
      opacity: 0;
    }
  }
`;
