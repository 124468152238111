import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      --_bi-toast-group-inline-size: var(--bi-size-layout-l);
      position: fixed;
      z-index: 9999;
      inset-inline: var(--bi-size-space-m);
      inset-block-end: var(--bi-size-space-m);
      pointer-events: none;
    }

    ::slotted(bi-toast) {
      inline-size: 100%;
      max-inline-size: var(--bi-size-layout-xxs);
      pointer-events: auto;
    }

    .bi-toast-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--bi-size-space-m);
    }
  `];
