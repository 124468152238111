import { DOCUMENT } from '@angular/common'
import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  Inject,
  inject,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core'
import { BingliAuthService } from '@mybingli/security-service'
import { TranslateService } from '@ngx-translate/core'
import { localStorageLanguageKey } from '@shared/data/constants'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { TooltipService } from '@shared/services/tooltip.service'
import { ResolveStart, Router } from '@angular/router'
import { combineLatest, debounceTime } from 'rxjs'
import { GlobalLoaderService } from '@shared/services/global-loader.service'
import { ModalService } from '@shared/services/modal.service'
import { UserActivityService } from '@shared/services/user-activity.service'
import { ToastService } from '@mybingli/toast-service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  @HostBinding('class.loading') loading = false
  @ViewChild('modalContainer', { read: ViewContainerRef })
  viewContainerRef?: ViewContainerRef
  @ViewChild('tooltipContainer', { read: ViewContainerRef })
  tooltipContainer?: ViewContainerRef
  private userActivityService = inject(UserActivityService)

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: BingliAuthService,
    private translateService: TranslateService,
    private tooltipService: TooltipService,
    private modalService: ModalService
  ) {}

  private toastService = inject(ToastService)
  private elementRef = inject(ElementRef)
  private destroyRef = inject(DestroyRef)
  private router = inject(Router)
  private globalLoader = inject(GlobalLoaderService)

  ngOnInit() {
    this.userActivityService.initObserver()
    this.authService.checkTokenExpiry()

    /* Sync <html lang="?"> with selected language */
    this.translateService.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(langChange => {
      localStorage.setItem(localStorageLanguageKey, langChange.lang)
      this.document.documentElement.lang = langChange.lang
    })

    this.authService
      .tokenStream()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          if (!response) {
            this.authService.setTokenAvailable(true)
          }
        }
      })

    combineLatest([this.globalLoader.isLoading$, this.router.events.pipe(debounceTime(300))]).subscribe(
      ([isLoading, routerEvent]) => (this.loading = isLoading || routerEvent instanceof ResolveStart)
    )
  }

  ngAfterViewInit() {
    this.tooltipService.setViewContainerRef(this.tooltipContainer)
    this.modalService.setViewContainerRef(this.viewContainerRef)
    this.toastService.initialize(this.elementRef)
  }
}
