import { Gender } from '../data/enums'
import { FilterSettings } from '../data/models/filter-settings'

export interface TenantUser {
  id?: string
  firstName: string
  middleName?: string
  lastName: string
  email: string
  mobilePhoneNumber?: string
  gender?: Gender
  language: string
  dateOfBirth?: string
  profilePic?: string
  allowReInvite: boolean
  userGroupIds?: string[]
  healthCareServiceSettings?: HealthCareServiceSettings[]
}

export interface SaveTenantUserRequest {
  firstName: string
  middleName?: string
  lastName: string
  email: string
  mobilePhoneNumber?: string
  gender?: Gender
  language: string
  dateOfBirth?: string
  userGroupIds?: string[]
}

export interface HealthCareServiceSettings {
  healthCareServiceId: string
  restrictedActions: string[]
}

export interface UserFilterSettings extends FilterSettings {
  sortField: UserSortFields
  userIds?: string[]
  excludeEmptyEmail?: string
  creationContext: UserCreationContext
}

export interface UserGroup {
  id: string
  tenantId: string
  isTenantDefault: boolean
  healthCareServiceId: string
  name: string
  description: string
}

export enum UserSortFields {
  None = 0,
  Email = 1,
  LogonLastSuccessful = 2,
  DisplayName = 3,
  CreatedOn = 4,
  LogonLocked = 5,
  Language = 6,
  Id = 7,
  FirstName = 8,
  LastName = 9
}

export enum UserCreationContext {
  BackOffice = 0,
  Patient = 1
}
