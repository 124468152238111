var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, nothing, render } from "lit";
import { customElement, property, queryAssignedElements, queryAssignedNodes, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { classMap } from "lit/directives/class-map.js";
import buttonStyles from "./button.styles";
import { LightLinkController } from "../shared/controllers/light-link.controller";
import aiIcon from "../shared/icons/ai.icon";
/**
 * A clickable button.
 * @slot - The content of the button.
 * @slot start - The content to render before the button's content.
 * @slot end - The content to render after the button's content.
 * @slot link - Use this slot as an alternative to the href property to render a button as a link. Useful for links that are dynamically generated. For example Angular which uses routerLink to generate the href if it is applied to an anchor tag.
 * @cssprop [--bi-button-color = var(--bi-color-text-default)] - The color of the button.
 * @cssprop [--bi-button-color--hover = var(--bi-color-text-default)] - The color of the button when hovered.
 * @cssprop [--bi-button-background-color = var(--bi-color-background)] - The background color of the button.
 * @cssprop [--bi-button-background-color--hover = var(--bi-color-neutral-default)] - The background color of the button when hovered.
 * @cssprop [--bi-button-border-color = var(--bi-color-border-default)] - The border color of the button.
 * @cssprop [--bi-button-border-color--hover = var(--bi-color-border-shade)] - The border color of the button when hovered.
 * @cssprop [--bi-button-color-outline = var(--bi-color-outline)] - The color of the button outline.
 * @cssprop [--bi-button-target-increase = 0px] - The amount the button’s target area should be increased.
 * @cssprop [--bi-button-z-index = 0] - The z-index of the button.
 */
let Button = class Button extends LitElement {
  constructor() {
    super(...arguments);
    /** The size of the button. */
    this.size = "m";
    /** Whether the button expands to fit its container in an inline direction. */
    this.expand = false;
    /** Whether the button is disabled. Doesn't have an effect when href is provided. */
    this.disabled = false;
    /** The direction of the button. Choose column to stack the content and slots vertically. */
    this.direction = "row";
    /** Determines the alignment of the button's content along the inline axis when expanded and the direction is row. */
    this.justifyContent = "center";
    /** When href is not provided, sets the type of the button. */
    this.type = "button";
    /** When provided together with a href property, determines where to open the linked URL. */
    this.target = "_self";
    /** Whether the button has a square aspect ratio. */
    this.square = false;
    /** The pressed state of a button. Useful for toggle buttons. */
    this.pressed = false;
    this.hasSlottedNodes = false;
    this.hasSlottedElements = false;
    this.lightLink = new LightLinkController(this, () => {
      var _a;
      return (_a = this.slottedLinks) === null || _a === void 0 ? void 0 : _a[0];
    });
  }
  updated() {
    if (this.type === "submit" && !this.href) {
      this.renderProxySubmitButton();
    }
    if (this.lightLink.text) {
      this.setAttribute("light-link", "");
    } else {
      this.removeAttribute("light-link");
    }
  }
  render() {
    const isToggle = this.getAttribute("slot") === "toggle";
    const toggleIconHTML = isToggle && !this.hasSlottedElements ? html`<svg
            class="bi-button-toggleIcon"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-169.4 169.4-169.4-169.3c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
              fill="currentColor"
            />
          </svg>` : nothing;
    const contentClasses = {
      "bi-button-content": true,
      "bi-button-content--hasSlottedContent": this.hasSlottedNodes
    };
    const contentHTML = html` <slot
        name="start"
        @slotchange=${this.handleSlotChange}
      ></slot>
      ${this.variant === "ai" ? aiIcon("bi-button-aiIcon") : nothing}
      <div class=${classMap(contentClasses)}>
        <slot @slotchange=${this.handleSlotChange}></slot>
        ${this.lightLink.text || ""}
      </div>
      <slot name="end" @slotchange=${this.handleSlotChange}></slot>
      ${toggleIconHTML}`;
    const buttonHTML = this.lightLink.text ? html` <div class="bi-button">${contentHTML}</div>` : this.href ? html`
          <a
            class="bi-button"
            href=${this.href}
            target=${ifDefined(this.target)}
          >
            ${contentHTML}
          </a>
        ` : html`
          <button
            class="bi-button"
            ?disabled=${this.disabled}
            @click=${this.handleClick}
          >
            ${contentHTML}
          </button>
          <slot name="proxy"></slot>
        `;
    return html`${buttonHTML} <slot name="link"></slot>`;
  }
  handleClick(e) {
    const form = this.closest("form");
    if (this.type === "submit") {
      e.stopPropagation();
      const proxySubmitButton = this.querySelector(`button[type="submit"]`);
      proxySubmitButton === null || proxySubmitButton === void 0 ? void 0 : proxySubmitButton.click();
    } else if (this.type === "reset") {
      form === null || form === void 0 ? void 0 : form.reset();
    }
  }
  /** Adds a proxy submit button to the light DOM to trigger an instrinsic submission on the form when the user presses enter in a text field in the same form or clicks the shadowed button when it has the type `submit`. */
  renderProxySubmitButton() {
    const proxyButtonHTML = html`<button
      type="submit"
      slot="proxy"
      tabindex="-1"
    ></button>`;
    render(proxyButtonHTML, this);
  }
  handleSlotChange() {
    this.hasSlottedNodes = !!this.slottedNodes.length;
    if (this.slottedElements.length || this.slottedStartElements.length || this.slottedEndElements.length) {
      this.hasSlottedElements = true;
    }
  }
};
Button.styles = buttonStyles;
Button.shadowRootOptions = {
  ...LitElement.shadowRootOptions,
  delegatesFocus: true
};
__decorate([queryAssignedElements()], Button.prototype, "slottedElements", void 0);
__decorate([queryAssignedNodes()], Button.prototype, "slottedNodes", void 0);
__decorate([queryAssignedElements({
  slot: "start"
})], Button.prototype, "slottedStartElements", void 0);
__decorate([queryAssignedElements({
  slot: "end"
})], Button.prototype, "slottedEndElements", void 0);
__decorate([queryAssignedElements({
  slot: "link",
  selector: "a"
})], Button.prototype, "slottedLinks", void 0);
__decorate([property()], Button.prototype, "variant", void 0);
__decorate([property()], Button.prototype, "size", void 0);
__decorate([property({
  type: Boolean
})], Button.prototype, "expand", void 0);
__decorate([property({
  type: Boolean
})], Button.prototype, "disabled", void 0);
__decorate([property()], Button.prototype, "direction", void 0);
__decorate([property({
  attribute: "justify-content"
})], Button.prototype, "justifyContent", void 0);
__decorate([property()], Button.prototype, "type", void 0);
__decorate([property()], Button.prototype, "href", void 0);
__decorate([property()], Button.prototype, "target", void 0);
__decorate([property({
  type: Boolean
})], Button.prototype, "square", void 0);
__decorate([property({
  type: Boolean
})], Button.prototype, "pressed", void 0);
__decorate([state()], Button.prototype, "hasSlottedNodes", void 0);
__decorate([state()], Button.prototype, "hasSlottedElements", void 0);
Button = __decorate([customElement("bi-button")], Button);
export { Button };
