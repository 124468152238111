var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { NotificationMixin } from "../shared/mixins/notification.mixin";
import toastGroupStyles from "./toast-group.styles";
/**
 * A group of toast notifications.
 * @slot - The slot to place the toasts in.
 */
let ToastGroup = class ToastGroup extends NotificationMixin(LitElement) {
  render() {
    return html`<div
      class="bi-toast-group"
      role="log"
      aria-relevant="additions"
    >
      <slot></slot>
    </div>`;
  }
  /** Adds a toast to the group. */
  addToast(text, options) {
    const toast = document.createElement("bi-toast");
    toast.textContent = text;
    if (options === null || options === void 0 ? void 0 : options.autoDismiss) {
      toast.autoDismiss = options.autoDismiss;
    }
    if (options === null || options === void 0 ? void 0 : options.variant) {
      toast.variant = options.variant;
    }
    this.appendChild(toast);
  }
};
ToastGroup.styles = toastGroupStyles;
ToastGroup = __decorate([customElement("bi-toast-group")], ToastGroup);
export { ToastGroup };
