import { inject, Injectable } from '@angular/core'
import { BingliAuthService } from '@mybingli/security-service'

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  private inactivityTime = 15 * 60 * 1000
  private timeoutId: any
  private authService = inject(BingliAuthService)

  initObserver() {
    this.resetTimeout()
    this.setupListeners()
  }

  private setupListeners() {
    window.addEventListener('mousemove', () => this.resetTimeout())
    window.addEventListener('keydown', () => this.resetTimeout())
    window.addEventListener('click', () => this.resetTimeout())
    window.addEventListener('scroll', () => this.resetTimeout())
  }

  private resetTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    this.timeoutId = setTimeout(() => this.authService.logout({ expired: 'true' }), this.inactivityTime)
  }
}
