import { APOLLO_NAMED_OPTIONS, ApolloModule } from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';

import { BrowserModule } from '@angular/platform-browser';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { onError } from '@apollo/client/link/error';
import { localStorageLanguageKey } from 'src/app/shared/data/constants'

// Feature toggle to switch to the old graphql endpoint from dashboard API
const surveysBaseURL = localStorage.getItem('oldDashboardGraphQL') ? environment.surveyApi : environment.dashboardApi;
const surveysUri = `${surveysBaseURL}/tenant-api/graphql/Surveys`;

const patientsUri = `${environment.bossApi}/tenant-api/graphql/Patients`;

export function createNamedApollo(
  httpLink: HttpLink
): Record<string, ApolloClientOptions<any>> {
  return {
    surveys: createDefaultApollo(httpLink, surveysUri),
    patients: createDefaultApollo(httpLink, patientsUri),
  };
}

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    if (
      (graphQLErrors[0] as any)?.statusCode >= 400 &&
      (graphQLErrors[0] as any)?.statusCode < 500
    ) {
      console.error(`[Client side error]: ${graphQLErrors[0].message}`);
    } else {
      console.error(`[Server side error]: ${graphQLErrors[0].message}`);
    }
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError.message}`);
  }
});

export function createDefaultApollo(
  httpLink: HttpLink,
  uri: string
): ApolloClientOptions<any> {

  const http = httpLink.create({ uri: () => { 
    const language = localStorage.getItem(localStorageLanguageKey) ?? "EN-GB"
    return `${uri}?language=${language}`
  }});

  return {
    connectToDevTools: !environment.production,
    assumeImmutableResults: true,
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, http]),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true,
      },
    },
  };
}
@NgModule({
  exports: [ApolloModule],
  imports: [BrowserModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createNamedApollo,
      deps: [HttpLink],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class GraphQLModule {}
