import { css } from "lit";
export default css`
  @keyframes notification-enter {
    0% {
      transform: translateY(50%);
      opacity: 0;
    }
  }

  @keyframes notification-exit {
    100% {
      opacity: 0;
    }
  }
`;
