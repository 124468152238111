import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

import { AboutDialogComponent } from '../dialogs/about-dialog/about-dialog.component';
import { AccountService } from 'src/app/modules/account/account.service';
import { BingliAuthService } from '@mybingli/security-service';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '../../utils/until-destroyed';
import { ClientService } from '@shared/services/client.service';
import { AsyncPipe } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  imports: [AsyncPipe, TranslateModule, RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserMenuComponent implements OnInit {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger | undefined;

  public userImagePath$ = this.accountService.profilePic$;
  readonly untilDestroyed = untilDestroyed();

  constructor(
    private authService: BingliAuthService,
    private accountService: AccountService,
    private clientService: ClientService,
    private dialog: MatDialog,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    this.fetchUserProfileOnceTokenIsAvailable();
  }

  closeMenu() {
    if (this.menuTrigger) this.menuTrigger.closeMenu();
  }

  showAboutDialog() {
    this.dialog.open(AboutDialogComponent, {
      width: '350px',
    });
  }

  logout() {
    this.authService.logout();
  }

  private fetchUserProfileOnceTokenIsAvailable() {
    this.authService
      .tokenStream()
      .pipe(this.untilDestroyed())
      .subscribe((tokenAvailable) => {
        if (
          tokenAvailable &&
          Boolean(this.authService.getTokens().bingli_token)
        ) {
          this.accountService.initializeService();
          this.clientService.initializeService();
        }
      });
  }
}
