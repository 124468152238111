import { Component, CUSTOM_ELEMENTS_SCHEMA, DestroyRef, inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NavigationEnd, Router } from '@angular/router'
import { BingliAuthService } from '@mybingli/security-service'
import { ZendeskCustomField } from '@shared/data/enums'
import { filter } from 'rxjs'

@Component({
  selector: 'bingli-zendesk-widget',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './zendesk-widget.component.html',
  styleUrl: './zendesk-widget.component.scss'
})
export class ZendeskWidgetComponent {
  private isWidgetOpen = false

  private authService = inject(BingliAuthService)
  private router = inject(Router)
  private destroyRef = inject(DestroyRef)

  ngOnInit() {
    this.setWidgetState(false)
    ;(window as any).zE('messenger:on', 'close', () => {
      this.setWidgetState(false)
    })

    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        filter(() => this.isWidgetOpen)
      )
      .subscribe(() => {
        this.setConversationFields()
      })
  }

  toggleWidget(): void {
    this.setWidgetState(!this.isWidgetOpen)
  }

  private setWidgetState(open: boolean): void {
    const zE = (window as any).zE
    if (open) {
      this.setConversationFields()
      zE('messenger', 'show')
      zE('messenger', 'open')
    } else {
      zE('messenger', 'close')
      zE('messenger', 'hide')
    }

    this.isWidgetOpen = open
  }

  private setConversationFields(): void {
    const { userId, email, tenantId, healthCareServiceId } = this.authService.getAuthInfo()
    const currentUrl = window.location.href

    ;(window as any).zE('messenger:set', 'conversationFields', [
      { id: ZendeskCustomField.UserId, value: userId },
      { id: ZendeskCustomField.PractitionerEmail, value: email },
      { id: ZendeskCustomField.TenantId, value: tenantId },
      { id: ZendeskCustomField.HcsId, value: healthCareServiceId },
      { id: ZendeskCustomField.Url, value: currentUrl }
    ])
  }
}
