var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { state } from "lit/decorators.js";
import { createRef } from "lit/directives/ref.js";
import { oneAnimationEvent } from "../utils/event.util";
export const NotificationMixin = superClass => {
  class NotificationElement extends superClass {
    constructor() {
      super(...arguments);
      this.notificationRef = createRef();
      this.dismissed = false;
    }
    async dismiss() {
      const notification = this.notificationRef.value;
      if (notification) {
        this.dismissed = true;
        await oneAnimationEvent(notification);
        this.dispatchEvent(new Event("dismiss", {
          bubbles: true,
          composed: true
        }));
      }
    }
  }
  __decorate([state()], NotificationElement.prototype, "dismissed", void 0);
  return NotificationElement;
};
